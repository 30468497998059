import React, { useEffect, useState } from 'react'

import './modal.css';

const Modal = (props) => {

    const [showModal, setShowModal] = useState(false);

    const handleCloseModal = (e) => {
        e.preventDefault();
        setShowModal(!showModal);
    }

    useEffect(() => {
        return () => {            
            setShowModal(!showModal);
        }
    }, [props.active])

    return (
        <div className={`modal _${props.name} ${showModal && '_open' || ''}`}>
            <div className="modal__bg"></div>
            <div className="modal__wrap">
                <a className="modal__close" href="#" onClick={handleCloseModal}></a>
                {props.children}
            </div>
        </div>
	)
}

export default Modal;
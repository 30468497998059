import React from 'react'

import icons from './icons.svg'

const Icon = props => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            role="img"
            className={`icon_${props.name}`}
            width={`${props.width}`}
            height={`${props.height}`}
            viewBox={`${props.viewBox}`}
		>
			<use xlinkHref={`${icons}#${props.name}`} />
		</svg>
	)
}

export default Icon;
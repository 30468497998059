import React, { useState, useContext, useRef, useEffect } from 'react';

export const compare = (key, order = 'asc') => {
  return function innerSort(a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
      return 0;
    }

    const varA = (typeof a[key] === 'string')
      ? a[key].toUpperCase() : a[key];
    const varB = (typeof b[key] === 'string')
      ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return (
      (order === 'desc') ? (comparison * -1) : comparison
    );
  };
}

export const declension = (number, titles) => {
  let cases = [2, 0, 1, 1, 1, 2];  
  return titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ];  
}

let hasPassiveSupport = undefined;
const getHasPassiveEventSupport = () => {
  if (typeof hasPassiveSupport === 'boolean') {
    return hasPassiveSupport;
  }
  
  hasPassiveSupport = false;

  try {
    const opts = Object.defineProperty({}, 'passive', {
      get() {
        hasPassiveSupport = true;
        return hasPassiveSupport;
      },
    });

    window.addEventListener('test', null, opts);
  } catch (e) {
    hasPassiveSupport = false;
  }

  return hasPassiveSupport;
};

export const useScrollPosition = () => {
  const [scroll, setScroll] = useState({x: 0, y: 0});
  const tickingRef = useRef();

  const handleScroll = () => {
    setScroll({
      x: window.pageXOffset,
      y: window.pageYOffset,
    });
    tickingRef.current = false;
  };
  
  const onScroll = () => {
    if (tickingRef.current) {
      return;
    }

    tickingRef.current = true;
    window.requestAnimationFrame(handleScroll);
  };

  useEffect(() => {
    window.addEventListener(
      'scroll',
      onScroll,
      getHasPassiveEventSupport() ? { passive: true } : false
    );

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  });

  return scroll;
}
import './item.css';

import React, { useState } from 'react'

import { BrowserRouter as Router, Link } from "react-router-dom";

import { declension } from '../../ui/ui';

import Photo from './img/photo-1.png';
import CommentIcon from './img/comment.svg';

import Modal from '../modal';

const Item = props => {

    const [showUser, setShowUser] = useState(false);

    const months = ['январь', 'февраль', 'март', 'апрель', 'май', 'июнь', 'июль', 'август', 'сентябрь', 'октябрь', 'ноябрь', 'декабрь'];

    const handleShowUser = (e) => {
        e.preventDefault();
        
        setShowUser(!showUser);

    }

	return (
        <>
            {props.type == 'author' && (
                <>
                    <div data-aos='fade-up' className="item _author">
                        <Link className="item__wrap" to={`/profile/${props.id}`} onClick={(e) => { !props.count &&  handleShowUser(e) }}>
                            <span className="item__header">
                                <span className="item__photo">
                                    <img src={props.img} alt=""/>
                                </span>
                                <span className="item__common">
                                    {props.count && <span className="item__count">
                                        {props.count}
                                        <img src={CommentIcon} alt=""/>
                                    </span>}
                                    <span className="item__title">{props.author} {props.id}</span>
                                    <span className="item__info">
                                        {props.proffession}<br/>
                                        <span>{props.nomination}</span>
                                    </span>
                                </span>
                            </span>
                            <span className="item__text">{props.shortDescription}</span>
                        </Link>
                    </div>
                    <Modal name="user" active={showUser} setShowModal={setShowUser}>
                        <div className="modal__header">
                            <div className="modal__photo"><img src={props.img} alt=""/></div>
                            <div className="modal__common">
                                <div className="modal__title">{props.author} {props.id}</div>
                                <div className="modal__info">
                                    {props.proffession}<br/>
                                    <span>{props.nomination}</span>
                                </div>
                            </div>
                        </div>
                        <div className="modal__content">{props.fullDescription}</div>
                    </Modal>
                </>
            )}
            {props.type == 'date' && (
                <div className="item _date">
                    <div className="item__wrap">
                        <div className="item__day">{props.day}</div>
                        <div className="item__month">{months[props.month]}</div>
                        <div className="item__year">{props.year}</div>
                    </div>
                </div>
            )}
            {props.type == 'category' && (
                <div className="item _category">
                    <a className="item__wrap" href="#" onClick={e => {e.preventDefault(); props.handleScrollCat(props.id); }}>
                        <span className="item__img"><img src={props.img} alt={props.title}/></span>
                        <span className="item__title">{props.title}</span>
                    </a>
                </div>
            )}
            {props.type == 'people' && (
                <div data-aos='fade-up' className={`item _people ${props.active == props.id && '_active' || ''}`}>
                    <div className="item__loader">
                        <span style={{ width: 100 - (props.count / parseInt(props.max) * 100) + '%' }}></span>
                    </div>
                    <Link className="item__wrap" to={`/peoples/${props.id}`}>
                        <span className="item__author">
                            <span className="item__author-photo"><img src={Photo} alt=""/></span>
                            <span className="item__author-common">
                                <span className="item__author-name">{props.author} {props.id}</span>
                                <span className="item__author-info">{props.proffession}<br/><span>{props.nomination}</span></span>
                            </span>
                        </span>
                        <span className="item__count"><span>{props.count}</span>{declension(props.count, ['заявка','заявки','заявок'])}</span>
                    </Link>
                </div>
            )}
            {props.type == 'commentShort' && (
                <div className="item _comments">
                    <div className="item__date">17.12.2019</div>
                    <div className="item__text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt...</div>
                </div>
            )}
            {props.type == 'commentFull' && (
                <div className="item _comment">
                    <div className="item__common">
                        <div className="item__date">10.10.2019 <span className="item__time">20:04</span></div>
                        <div className="item__text">The John Wick saga is continuing this month with John Wick: Chapter 3 – Parabellum (the title of which role.</div>
                    </div>
                    <div className="item__author">
                        <div className="item__author-photo"><img src={Photo} alt=""/></div>
                        <div className="item__author-common">
                            <div className="item__author-name">Vitaliy Varlamov</div>
                            <div className="item__author-info">Englewood, CO</div>
                        </div>
                    </div>
                </div>
            )}
        </>
	)
}

export default Item;
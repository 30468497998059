import React from 'react';

export const Context = React.createContext({
    modals: {
        navbar: false,
        order: false
    },
    categories: {
        type1: {
            title: 'Эксперт года в R&D'
        },
        type2: {
            title: 'Предприниматель года'
        },
        type3: {
            title: 'Профессионал года'
        },
        type4: {
            title: 'Команда года'
        },
        type5: {
            title: 'Инновация года'
        },
        type6: {
            title: 'Человек года'
        }
    },
    onShowNavbar: () => {},
    onShowOrder: () => {}
});
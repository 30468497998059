import "./categories.css";
import "react-datepicker/dist/react-datepicker.css";
import 'aos/dist/aos.css';

import React, { useState, useEffect, useRef } from 'react';

import { StickyContainer, Sticky } from 'react-sticky';

import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import ru from 'date-fns/locale/ru';
import AOS from 'aos';

import DataPeoples from './data';

import { compare } from '../../ui/ui';

import Item from '../../components/item';
import Icon from '../../components/icon';

import nominate_1 from './img/1.svg';
import nominate_2 from './img/2.svg';
import nominate_3 from './img/3.svg';
import nominate_4 from './img/4.svg';
import nominate_5 from './img/5.svg';
import nominate_6 from './img/6.svg';

registerLocale('ru', ru)

export default function Categories(){

    const [filterType, setFilterType] = useState('');
    const [selectDate, setSelectDate] = useState('');
    const [peoples, setPeoples] = useState([]);
    const [peoplesFinalists, setPeoplesFinalists] = useState(false);
    const ref = useRef(null);

    const filterPeoples = () => {
        let changeData = {},
            finalistsData = {},
            isType = false,
            isDate = false;
        if(filterType !== ''){
            isType = true;
        }
        if(selectDate !== ''){
            isDate = true;
        }
        DataPeoples.map((item, index) => {
            let itemDate = new Date(parseInt(item.dataCreate)),
                compareData = function(){
                    if(changeData[item.dataCreate]){
                        changeData[item.dataCreate] = [...changeData[item.dataCreate], item]
                    } else {
                        changeData[item.dataCreate] = [item]
                    }
                };
            if(!isType && !isDate){
                compareData();
            } else if(isType && !isDate){
                if(item.category == filterType){
                    compareData();
                }
            } else if(!isType && isDate){
                if(
                    selectDate.getDate() == itemDate.getDate() &&
                    selectDate.getMonth() == itemDate.getMonth() &&
                    selectDate.getFullYear() == itemDate.getFullYear()
                ){
                    compareData();
                }
            } else if(isType && isDate) {
                if(
                    item.category == filterType && 
                    selectDate.getDate() == itemDate.getDate() &&
                    selectDate.getMonth() == itemDate.getMonth() &&
                    selectDate.getFullYear() == itemDate.getFullYear()
                ){
                    compareData();
                }
            }

            
            if(finalistsData[item.category]){
                finalistsData[item.category] = [...finalistsData[item.category], item]
            } else {
                finalistsData[item.category] = [item]
            }

        });
        setPeoples(changeData);
        setPeoplesFinalists(finalistsData);
    }

    useEffect(() => {
        filterPeoples();
    }, [DataPeoples, filterType, selectDate]);

    AOS.init({
        once: true
    })

    let nominatesFilters = [
        {
            id: 0,
            img: '',
            key: '',
            title: 'Все номинации'
        },{
            id: 1,
            img: nominate_1,
            key: 'type1',
            title: 'Эксперт года в R&D'
        },{
            id: 2,
            img: nominate_2,
            key: 'type2',
            title: 'Предприниматель года'
        },{
            id: 3,
            img: nominate_3,
            key: 'type3',
            title: 'Профессионал года'
        },{
            id: 4,
            img: nominate_4,
            key: 'type4',
            title: 'Команда года'
        },{
            id: 5,
            img: nominate_5,
            key: 'type5',
            title: 'Инновация года'
        },{
            id: 6,
            img: nominate_6,
            key: 'type6',
            title: 'Человек года'
        }
    ]

    const refs = nominatesFilters.reduce((acc, value) => {
        acc[value.id] = React.createRef();
        return acc;
    }, {});

    const handleScrollCat = (id) => {
        window.scrollTo(0, refs[id].current.getBoundingClientRect().top + window.pageYOffset);
    }
        
    const ButtonDatepicker = ({ value, onClick }) => (
        <>
            <a href="#" className={`${value && 'active' || ''}`} onClick={(e) => { e.preventDefault(); onClick() }}>{value && value || 'Дата'}
                {!value && <Icon name="chevron-bottom-small" width="10px" height="7px" viewBox="0 0 10 7" />}
            </a>
            {value && <a href="#" className="clear-filter-date" onClick={e => {e.preventDefault(); setSelectDate('')}}>x</a>}
        </>
    );

    return (
        <>
            <div className="categories">
                <div className="container">
                    <StickyContainer>
                        <Sticky>
                            {({
                                style,
                                isSticky,
                                wasSticky,
                                distanceFromTop,
                                distanceFromBottom,
                                calculatedHeight
                            }) => (
                                <div className={`categories__menu ${isSticky && '_sticky' || ''}`} style={style}>
                                    <ul>
                                        {nominatesFilters.map((item, index) => {
                                            return (
                                                <li data-aos='fade-down' data-aos-delay={index * 100} key={index} className={`${filterType == item.key && 'active' || ''}`}>
                                                    <a href="#" onClick={(e) => { e.preventDefault(); setFilterType(item.key) }}>{item.title}</a>
                                                </li>
                                            )
                                        })}
                                        <li data-aos='fade-down' data-aos-delay={nominatesFilters.length * 100}>
                                            <DatePicker
                                                selected={selectDate}
                                                onChange={date => setSelectDate(date)}
                                                maxDate={new Date()}
                                                locale="ru"
                                                customInput={<ButtonDatepicker />}
                                                calendarClassName="filter_datepicker"
                                                showPopperArrow={false}
                                            />
                                        </li>
                                    </ul>
                                </div>
                            )}
                        </Sticky>
                        <div className="categories__list-dates">
                            {Object.keys(peoples).sort().reverse().map((item, index) => {
                                let itemDate = new Date(parseInt(item));
                                return (
                                    <div key={item} className="categories__list-group">
                                        <StickyContainer>
                                            <Sticky>
                                                {({
                                                    style,
                                                    isSticky,
                                                    wasSticky,
                                                    distanceFromTop,
                                                    distanceFromBottom,
                                                    calculatedHeight
                                                }) => (
                                                    <div className={`categories__filter ${isSticky && '_sticky' || ''}`} style={style}>
                                                        <Item type="date" day={itemDate.getDate()} month={itemDate.getMonth()} year={itemDate.getFullYear()} />
                                                    </div>
                                                )}
                                            </Sticky>
                                            <div className="categories__list">
                                                {peoples[item].map((item, index) => (
                                                    <Item key={index} type="author" {...item} count={false} />
                                                ))}
                                            </div>
                                        </StickyContainer>
                                    </div>
                                )
                            })}
                        </div>
                    </StickyContainer>
                </div>
            </div>
            <div className="categories">
                <div className="container">
                    <div className="categories__group">
                        <div className="categories__group-title">Список номинаций</div>
                        <div className="categories__group-wrap">
                            <div className="categories__types">
                                {nominatesFilters.map((item, index) => {
                                    return (
                                        <>
                                            {index > 0 && <Item type="category" handleScrollCat={handleScrollCat} {...item} />}
                                        </>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    {Object.keys(peoplesFinalists).map((item, index) => {
                        return (
                            <div ref={refs[index + 1]} key={index} className="categories__group">
                                <div className="categories__group-title">{nominatesFilters.find(x => x.key === item).title}</div>
                                <div className="categories__group-wrap">
                                    <div className="categories__list">
                                        {peoplesFinalists[item].sort(compare('count', 'desc')).map((item, index) => {
                                            return (
                                                <>
                                                    {index < 4 && <Item key={index} type="author" {...item} />}
                                                </>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    )
}
import './profile.css';

import React, { useState, useEffect, useContext } from 'react';

import { Context } from '../../context';

import { BrowserRouter as Router, Link } from "react-router-dom";

import { compare } from '../../ui/ui';

import Item from '../../components/item';

import Photo2 from './img/photo-2.png';
import Photo3 from './img/photo-3.png';
import Photo4 from './img/photo-4.png';

import peoples from '../../modules/categories/data';

export default function Profile(props) {


  const [typesPeoples, setTypesPeoples] = useState([]);
  const [currentProfile, setCurrentProfile] = useState({
    img: ''
  });
  const [nextProfile, setNextProfile] = useState({
    img: ''
  });
  const [prevProfile, setPrevProfile] = useState({
    img: ''
  });

  useEffect(() => {
    peoples.map((item, index) => {
      if(item.id == props.match.params.id){
        setCurrentProfile(item);
      }
    });
  }, [peoples, props.match.params.id])

  useEffect(() => {
    let typesPeoples = peoples.filter((item, index) => {
      return item.category == currentProfile.category
    });
    setTypesPeoples(typesPeoples.sort(compare('count', 'desc')));
  }, [currentProfile])


  useEffect(() => {
    typesPeoples.map((item, index) => {
      if(item.id == props.match.params.id){
        if(typesPeoples[index - 1]){
          setPrevProfile(typesPeoples[index - 1]);          
        } else {
          setPrevProfile(typesPeoples[typesPeoples.length - 1]);
        }
        if(typesPeoples[index + 1]){
          setNextProfile(typesPeoples[index + 1]);
        } else {
          setNextProfile(typesPeoples[0]);
        }
      }
    })
  }, [typesPeoples])

  const { categories } = useContext(Context);

  return (
    <div className="profile">
      <div className="profile__title"> 
        <div className="container">{categories && categories[currentProfile.category] && categories[currentProfile.category].title}</div>
      </div>
      <div className="profile__header">
        <div className="container">
          <div className="profile__prev"><Link to={`/profile/${prevProfile.id}`}><img src={prevProfile.img} alt=""/></Link></div>
          <div className="profile__next"><Link to={`/profile/${nextProfile.id}`}><img src={nextProfile.img} alt=""/></Link></div>
          <div className="profile__photo"><img src={currentProfile.img} alt=""/></div>
          <div className="profile__common">
            <div className="profile__name">{currentProfile.author} {currentProfile.id}</div>
            <div className="profile__info">{currentProfile.proffession}</div>
            <div className="profile__slogan">{currentProfile.shortDescription}</div>
          </div>
        </div>
      </div>
      <div className="profile__content">
        <div className="container">
          {currentProfile.fullDescription}
        </div>
      </div>
      <div className="profile__comments">
        <div className="container">
          <div className="comments">
            <div className="comments__title">Commetns <span className="comments__count">(5)</span></div>
            <div className="comments__list">

              <Item type="commentFull" />
              
            </div>
            <div className="comments__form">
              <div className="form _comments">
                <div className="form__field _sms">
                  <div className="input">
                    <textarea placeholder="Потому что..."></textarea>
                  </div>
                </div>
                <div className="form__field _buttons">
                  <button className="button button_green">Send</button><a className="button button_clear _small" href="#">Clear</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
import React from 'react'

import './button.css';

const Button = (props) => {
    let className = `button button_${props.name}`,
        customProps = {
            ...props,
            name: undefined
        }

    return (
        props.href && <a className={className} {...customProps}>{props.children}</a> || 
        <button className={className} {...customProps}>{props.children}</button>
	)
}

export default Button;
import "./user.css";

import React from 'react';

import PropTypes from 'prop-types';

import { declension } from '../../ui/ui';

import Item from '../../components/item';

export default function User(props){

  const { user } = props;

  return (
    <div className="user">
      <div className="user__header">
        <div className="user__photo"><img src={user.img} alt=""/></div>
        <div className="user__common">
          <div className="user__name">{user.author} {user.id}</div>
          <div className="user__info">{user.proffession}</div>
        </div>
      </div>
      <div className="user__count"><span>{user.count}</span>{declension(user.count, ['заявка','заявки','заявок'])}</div>
      <div className="user__content">
        <div className="user__comments">

          {[0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15].map((item, index) => (
            <Item key={index} type="commentShort" />
          ))}

        </div>
      </div>
    </div>
  )
}

User.defaultProps = {
  user: {
    photo: '',
    count: 0,
    author: '',
    proffession: ''
  }
};

User.propTypes = {
  user: PropTypes.shape({
    photo: PropTypes.string,
    author: PropTypes.string,
    proffession: PropTypes.string,
    count: PropTypes.number
  })
};
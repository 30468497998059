import React, { useState, useContext } from 'react';

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { Context } from './context';

import Entry from './modules/entry';
import Categories from './modules/categories';

import Navbar from './container/navbar';
import Modals from './container/modals';

import Peoples from './pages/peoples';
import Profile from './pages/profile';

export default function App(){

    const [showModalNavbar, setShowModalNavbar] = useState(false);
    const [showModalOrder, setShowModalOrder] = useState(false);
    const { categories } = useContext(Context);

    return (
        <Context.Provider value={{
            categories: categories,
            modals: {
                navbar: showModalNavbar,
                order: showModalOrder
            },
            onShowNavbar: setShowModalNavbar,
            onShowOrder: setShowModalOrder
        }}>
            <Router>
                <Navbar />
                <Switch>
                    <Route exact path="/">
                        <Entry />
                        <Categories />
                    </Route>
                    <Route path={["/peoples/:id", "/peoples"]} component={Peoples}/>
                    <Route path={["/profile/:id"]} component={Profile}/>
                </Switch>
                <Modals />
            </Router>
        </Context.Provider>
    )
}
import "./menu.css";

import React from 'react';

import Icon from '../../components/icon';

export default function Menu(props){

    return (
        <>
            {props.type == 'main' && (
                <ul>
                    <li><a href="#">Правила</a></li>
                    <li><a href="#">Этапы</a></li>
                    <li><a href="#">FAQ</a></li>
                    <li><a href="#">Навеки в истории</a></li>
                    <li><a href="#">AKA 2018</a></li>
                </ul>
            )}
        </>
    )

}
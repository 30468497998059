import React from 'react'

import './addOrder.css';

import Icon from '../../components/icon';
import Button from '../../components/button';

const AddOrder = (props) => {
    return (
        <div className="form _order">
            <div className="form__title">Подать заявку</div>
            <div className="form__wrap">
                <div className="form__group">
                    <div className="form__group-title">Выберите номинацию:<span className="bull"></span></div>
                    <div className="form__field">
                        <label className="select">
                            <div className="select__wrap">
                                <select name="select" defaultValue="1">
                                    <option value="1">Предприниматель года</option>
                                    <option value="2">Тест 2</option>
                                    <option value="3">Тест 3</option>
                                    <option value="4">Тест 4</option>
                                </select>
                                <div className="select__icon">
                                    <Icon name="chevron-bottom-small" width="10px" height="7px" viewBox="0 0 10 7" />
                                </div>
                            </div>
                        </label>
                    </div>
                    <div className="form__field">
                        <div className="input">
                            <input type="text" placeholder="Enter a name or e-mail address..."/>
                        </div>
                    </div>
                    <div className="form__field">
                        <div className="input">
                            <textarea placeholder="Потому что..."></textarea>
                        </div>
                    </div>
                </div>
                <div className="form__content">
                    <p><b>Персональная номинация для сотрудника из подразделений, занимающихся продажей и продвижением продукции компании, который покорил рынок своими решениями.</b></p>
                    <ul>
                        <li>• Номинант должен быть сотрудником одного из глобальных или региональных подразделений по продажам, маркетингу и развитию бизнеса. </li>
                        <li>• Номинант должен быть лидером, умеющим принимать взвешенные решения и просчитывать риски, а также профессионалом высочайшего класса, ориентированным на потребности рынка и наших клиентов </li>
                        <li>• Номинант должен инициировать или принимать участие в проектах, способствующих росту финансовых показателей компании, ее доли на рынке, формированию благоприятной репутации «Лаборатории Касперского»</li>
                    </ul>
                </div>
                <div className="form__footer">
                    <Button href="#" name="clear">Очистить</Button>
                    <Button name="green">Send</Button>
                </div>
            </div>
        </div>
	)
}

export default AddOrder;
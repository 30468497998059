import "./entry.css";

import React, { useState, useContext, useRef } from 'react';

import { BrowserRouter as Router, Link } from "react-router-dom";

import { useScrollPosition } from '../../ui/ui';

import { Context } from '../../context';

import Icon from '../../components/icon';
import Button from '../../components/button';

import Photo from './img/photo-4.png';
import IconPeoples from './img/icon.svg';

export default function Entry(){
    
    const { modals, onShowOrder } = useContext(Context);
    const [ scrollTopWindow, setScrollTopWindow ] = useState(0);

    const { y } = useScrollPosition();

    let handleShowOrder = e => {
        e.preventDefault();

        onShowOrder(!modals.order);
    }

    return (
        <>
            <div className="entry">
                <div className="container">
                    <div className="entry__common">
                        <div className="entry__title">Annual Kaspersky Awards 2019</div>
                        <div className="entry__sub-title">Ежегодная премия высших достижений «Лаборатории Касперского»</div>
                        <div className="entry__numbers">
                            <div className="item">
                            <div className="item__title">120</div>
                            <div className="item__caption">Дней осталось до&nbsp;объявления лауреатов </div>
                            </div>
                            <div className="item">
                            <div className="item__title">532</div>
                            <div className="item__caption">Заявки поданы в ходе выдвижения кандидатов</div>
                            </div>
                        </div>
                        <div className="entry__button">
                            <Button name="gray" onClick={handleShowOrder}>Подать заявку</Button>
                        </div>
                        <div className="entry__peoples">
                            <div className="entry__peoples-icon">
                                <img src={IconPeoples} alt=""/>
                            </div>
                            <div className="entry__peoples-common">
                                <div className="entry__peoples-title">People’s Choice</div>
                                <div className="entry__peoples-desc">Глас общественности, народное голосование, твой кандидат</div>
                                <Link to="/peoples" className="entry__peoples-link">Подробнее</Link>
                            </div>
                        </div>
                    </div>
                    <div className="entry__offset">
                        <div className="entry__offset-bg" style={{ transform: `rotate(${y / 10}deg)` }}></div>
                        <div className="offset">
                            <div className="offset__photo"><img src={Photo} alt=""/></div>
                            <div className="offset__common">
                            <div className="offset__title">Выбирать вам.</div>
                            <div className="offset__text">Все мы хорошо работали в 2019 году, но кто-то постарался лучше остальных. Этих героев надо обязательно найти и хорошенько поощрить. Смотрите внимательно по сторонам — они могут быть совсем рядом — и выдвигайте самых достойных на соискание Annual Kaspersky Awards!</div>
                            </div>
                        </div>
                    </div>
                    <div className="entry__next-screen">
                        <a href="#">
                            <Icon name="chevron-bottom" width="24px" height="16px" viewBox="0 0 24 16" />
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}
import Photo1 from './img/photo-1.png';

export default [
  {
    id: 1,
    img: Photo1,
    dataCreate: 1576586990997,
    category: 'type1',
    count: 2,
    author: 'Тест Тест',
    proffession: 'Должность',
    nomination: 'Эксперт года в R&D',
    shortDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt...',
    fullDescription: 'Контент'
  },{
    id: 2,
    img: Photo1,
    dataCreate: 1576586990997,
    category: 'type2',
    count: 2,
    author: 'Тест Тест',
    proffession: 'Должность',
    nomination: 'Предприниматель года',
    shortDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt...',
    fullDescription: 'Контент'
  },{
    id: 3,
    img: Photo1,
    dataCreate: 1576586990997,
    category: 'type3',
    count: 2,
    author: 'Тест Тест',
    proffession: 'Должность',
    nomination: 'Профессионал года',
    shortDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt...',
    fullDescription: 'Контент'
  },{
    id: 4,
    img: Photo1,
    dataCreate: 1576586990997,
    category: 'type4',
    count: 2,
    author: 'Тест Тест',
    proffession: 'Должность',
    nomination: 'Команда года',
    shortDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt...',
    fullDescription: 'Контент'
  },{
    id: 5,
    img: Photo1,
    dataCreate: 1576673548827,
    category: 'type5',
    count: 2,
    author: 'Тест Тест',
    proffession: 'Должность',
    nomination: 'Инновация года',
    shortDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt...',
    fullDescription: 'Контент'
  },{
    id: 6,
    img: Photo1,
    dataCreate: 1576673548827,
    category: 'type6',
    count: 2,
    author: 'Тест Тест',
    proffession: 'Должность',
    nomination: 'Человек года',
    shortDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt...',
    fullDescription: 'Контент'
  },{
    id: 7,
    img: Photo1,
    dataCreate: 1576586990997,
    category: 'type3',
    count: 2,
    author: 'Тест Тест',
    proffession: 'Должность',
    nomination: 'Профессионал года',
    shortDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt...',
    fullDescription: 'Контент'
  },{
    id: 8,
    img: Photo1,
    dataCreate: 1576586990997,
    category: 'type4',
    count: 2,
    author: 'Тест Тест',
    proffession: 'Должность',
    nomination: 'Команда года',
    shortDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt...',
    fullDescription: 'Контент'
  },{
    id: 9,
    img: Photo1,
    dataCreate: 1576673548827,
    category: 'type5',
    count: 2,
    author: 'Тест Тест',
    proffession: 'Должность',
    nomination: 'Инновация года',
    shortDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt...',
    fullDescription: 'Контент'
  },{
    id: 10,
    img: Photo1,
    dataCreate: 1576673548827,
    category: 'type6',
    count: 2,
    author: 'Тест Тест',
    proffession: 'Должность',
    nomination: 'Человек года',
    shortDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt...',
    fullDescription: 'Контент'
  },{
    id: 11,
    img: Photo1,
    dataCreate: 1576586990997,
    category: 'type1',
    count: 1,
    author: 'Тест Тест',
    proffession: 'Должность',
    nomination: 'Эксперт года в R&D',
    shortDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt...',
    fullDescription: 'Контент'
  },{
    id: 12,
    img: Photo1,
    dataCreate: 1576586990997,
    category: 'type2',
    count: 2,
    author: 'Тест Тест',
    proffession: 'Должность',
    nomination: 'Предприниматель года',
    shortDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt...',
    fullDescription: 'Контент'
  },{
    id: 13,
    img: Photo1,
    dataCreate: 1576586990997,
    category: 'type3',
    count: 2,
    author: 'Тест Тест',
    proffession: 'Должность',
    nomination: 'Профессионал года',
    shortDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt...',
    fullDescription: 'Контент'
  },{
    id: 14,
    img: Photo1,
    dataCreate: 1576586990997,
    category: 'type4',
    count: 2,
    author: 'Тест Тест',
    proffession: 'Должность',
    nomination: 'Команда года',
    shortDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt...',
    fullDescription: 'Контент'
  },{
    id: 15,
    img: Photo1,
    dataCreate: 1576673548827,
    category: 'type5',
    count: 2,
    author: 'Тест Тест',
    proffession: 'Должность',
    nomination: 'Инновация года',
    shortDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt...',
    fullDescription: 'Контент'
  },{
    id: 16,
    img: Photo1,
    dataCreate: 1576673548827,
    category: 'type6',
    count: 2,
    author: 'Тест Тест',
    proffession: 'Должность',
    nomination: 'Человек года',
    shortDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt...',
    fullDescription: 'Контент'
  },{
    id: 17,
    img: Photo1,
    dataCreate: 1576586990997,
    category: 'type3',
    count: 2,
    author: 'Тест Тест',
    proffession: 'Должность',
    nomination: 'Профессионал года',
    shortDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt...',
    fullDescription: 'Контент'
  },{
    id: 18,
    img: Photo1,
    dataCreate: 1576586990997,
    category: 'type4',
    count: 2,
    author: 'Тест Тест',
    proffession: 'Должность',
    nomination: 'Команда года',
    shortDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt...',
    fullDescription: 'Контент'
  },{
    id: 19,
    img: Photo1,
    dataCreate: 1576673548827,
    category: 'type5',
    count: 2,
    author: 'Тест Тест',
    proffession: 'Должность',
    nomination: 'Инновация года',
    shortDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt...',
    fullDescription: 'Контент'
  },{
    id: 20,
    img: Photo1,
    dataCreate: 1576673548827,
    category: 'type6',
    count: 2,
    author: 'Тест Тест',
    proffession: 'Должность',
    nomination: 'Человек года',
    shortDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt...',
    fullDescription: 'Контент'
  },{
    id: 21,
    img: Photo1,
    dataCreate: 1576586990997,
    category: 'type1',
    count: 1,
    author: 'Тест Тест',
    proffession: 'Должность',
    nomination: 'Эксперт года в R&D',
    shortDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt...',
    fullDescription: 'Контент'
  },{
    id: 22,
    img: Photo1,
    dataCreate: 1576673548827,
    category: 'type2',
    count: 2,
    author: 'Тест Тест',
    proffession: 'Должность',
    nomination: 'Предприниматель года',
    shortDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt...',
    fullDescription: 'Контент'
  },{
    id: 23,
    img: Photo1,
    dataCreate: 1576586990997,
    category: 'type3',
    count: 2,
    author: 'Тест Тест',
    proffession: 'Должность',
    nomination: 'Профессионал года',
    shortDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt...',
    fullDescription: 'Контент'
  },{
    id: 24,
    img: Photo1,
    dataCreate: 1576586990997,
    category: 'type4',
    count: 2,
    author: 'Тест Тест',
    proffession: 'Должность',
    nomination: 'Команда года',
    shortDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt...',
    fullDescription: 'Контент'
  },{
    id: 25,
    img: Photo1,
    dataCreate: 1576673548827,
    category: 'type5',
    count: 2,
    author: 'Тест Тест',
    proffession: 'Должность',
    nomination: 'Инновация года',
    shortDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt...',
    fullDescription: 'Контент'
  },{
    id: 26,
    img: Photo1,
    dataCreate: 1576673548827,
    category: 'type6',
    count: 2,
    author: 'Тест Тест',
    proffession: 'Должность',
    nomination: 'Человек года',
    shortDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt...',
    fullDescription: 'Контент'
  },{
    id: 27,
    img: Photo1,
    dataCreate: 1576586990997,
    category: 'type3',
    count: 2,
    author: 'Тест Тест',
    proffession: 'Должность',
    nomination: 'Профессионал года',
    shortDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt...',
    fullDescription: 'Контент'
  },{
    id: 28,
    img: Photo1,
    dataCreate: 1576586990997,
    category: 'type4',
    count: 2,
    author: 'Тест Тест',
    proffession: 'Должность',
    nomination: 'Команда года',
    shortDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt...',
    fullDescription: 'Контент'
  },{
    id: 29,
    img: Photo1,
    dataCreate: 1576673548827,
    category: 'type5',
    count: 2,
    author: 'Тест Тест',
    proffession: 'Должность',
    nomination: 'Инновация года',
    shortDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt...',
    fullDescription: 'Контент'
  },{
    id: 30,
    img: Photo1,
    dataCreate: 1576673548827,
    category: 'type6',
    count: 2,
    author: 'Тест Тест',
    proffession: 'Должность',
    nomination: 'Человек года',
    shortDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt...',
    fullDescription: 'Контент'
  }
]
import "./navbar.css";

import React, { useContext } from 'react';

import { Context } from '../../context';

import Menu from '../../modules/menu';

export default function Navbar(){

    const { modals, onShowNavbar } = useContext(Context);

    let handleShowNavbar = e => {
        e.preventDefault();

        onShowNavbar(!modals.navbar);
    }

    return (
        <>
            <div className="navbar">
                <div className="container">
                    <div className="navbar__menu">
                        <Menu type="categories" />
                    </div>
                    <a className="navbar__trigger" href="#" onClick={handleShowNavbar}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </a>
                </div>
            </div>
        </>
        
    )
}
import "./lang.css";

import React from 'react';

import Icon from '../../components/icon';
import Button from '../../components/button';

export default function Lang(){
    return (
        <>
            <Button href="#">EN &nbsp;
                <Icon name="chevron-bottom-small" width="10px" height="7px" viewBox="0 0 10 7" />
            </Button>
        </>
    )
}
import "./peoples.css";
import 'aos/dist/aos.css';

import React, { useState, useEffect } from 'react';

import { compare } from '../../ui/ui';

import AOS from 'aos';

import Item from '../../components/item';

import peoples from '../../modules/categories/data';

import User from '../../modules/user';

export default function Peoples(props){

  const [user, setUser] = useState();
  const [peoplesData, setPeoplesData] = useState([]);

  AOS.init({
    once: true
  })

  useEffect(() => {
    let sortPeoples = peoples.sort(compare('count', 'desc'));
    setPeoplesData(sortPeoples)
  }, [peoples])

  useEffect(() => {
    if(!props.match.params.id){
      props.history.push({
        pathname: `${props.match.path}/${peoples[0].id}`
      })
    }
    const userCurrent = peoplesData.filter((item, index) => {
      return item.id == props.match.params.id
    });
    setUser({...userCurrent[0]});
  }, [peoplesData, props.match.params.id]);

  return (
    <div className="peoples">
      <div className="container">
        <div className="peoples__title">People’s Choice</div>
        <div className="peoples__wrap">
          <div className="peoples__list">

            {peoplesData.map((item, index) => (
              <Item key={index} type="people" max={peoplesData[0].count} active={props.match.params.id} {...item} />
            ))}
        
          </div>

          {props.match.params.id && <div data-aos='fade-left' className="peoples__user">
            <div className="peoples__user-wrap">
              <User user={user} />
            </div>
          </div>}

        </div>
      </div>
    </div>
  )
}
import React, { useContext } from 'react'

import { Context } from '../../context';

import Icon from '../../components/icon';
import Button from '../../components/button';
import Modal from '../../components/modal';

import Menu from '../../modules/menu';
import AddOrder from '../../modules/addOrder';

import Lang from '../../modules/lang';

const Modals = (props) => {

    const { modals, onShowOrder } = useContext(Context);

    let handleShowOrder = e => {
        e.preventDefault();

        onShowOrder(!modals.order);
    }

    return (
        <>
            <Modal name="navbar" active={modals.navbar}>
                <Menu type="main" />
                <Button name="custom" href="#" onClick={handleShowOrder}>
                    <Icon name="pencil" width="23px" height="23px" viewBox="0 0 23 23"/>Подать заявку
                </Button>
            </Modal>
            <Modal name="order" active={modals.order}>
                <div className="modal__header">
                    <div className="container">
                        <div className="modal__lang">
                            <Lang />
                        </div>
                    </div>
                </div>
                <div className="modal__form">
                    <div className="container">
                        <AddOrder />
                    </div>
                </div>
            </Modal>
        </>
	)
}

export default Modals;